<template>
  <lock-scroll :locked="lockScroll">
    <lock-screen :locked="lockScreen" />
    <app-layout>
      <div class="w-full xl:px-36 sm:px-8">
        <div class="flex flex-col items-center pb-32" v-loading.fullscreen.lock="loading">
          <div class="w-full px-6 pt-6 2xl:w-3/5 2xl:px-0">
            <div class="w-full mb-4 space-y-4">
              <div class="flex flex-col md:flex-row py-4">
                <div class="flex flex-row items-center">
                  <sa-icon-button
                    with-bg
                    iconClass="bx bx-left-arrow-alt"
                    @click="$router.push({ name: 'orders.read', params: { id: order.id } })" />
                  <span class="mx-2 text-lg font-bold">
                    Factura
                  </span>
                </div>
                <div class="flex flex-row items-center ml-auto">
                  <el-button @click="goPoliciesAndTerms" class="terms-button" :class="{ 'p-2' : isMobile }">
                    <span v-if="!isMobile">Configuración</span>
                    <i v-if="isMobile" class='text-md bx bx-cog'></i>
                  </el-button>
                  <div class="whitespace-nowrap">
                    <quick-start-tip
                      title="Imprime tus recibos"
                      content="Puedes imprimir tus ordenes en diferentes formatos (Carta, Termico y Stickers)."
                      placement="bottom"
                      :bg-purple="false"
                      :value="activeQuickStartTip === 9"
                      @onNextStep="handleNextStep">
                      <div class="inline-block" :class="{ 'quick-start-selected': $store.state.quickStart.step === 9 }">
                        <el-dropdown v-if="id && !isMobile" split-button type="warning" class="mx-2" @click="print" @command="print">
                          <span>Imprimir</span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item disabled>Carta</el-dropdown-item>
                            <el-dropdown-item command="thermal">Térmico</el-dropdown-item>
                            <el-dropdown-item command="sticker">Sticker</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </quick-start-tip>
                    <el-button-group v-if="id && isMobile" class="mr-2" style="margin-top: -6px;">
                      <span class="el-button el-button--warning p-2" @click="print">
                        <i class="text-md bx bx-printer"></i>
                      </span>
                      <el-dropdown trigger="click" @command="print">
                        <span class="el-button el-button--warning p-2">
                          <i class="text-md el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item disabled>Carta</el-dropdown-item>
                          <el-dropdown-item command="thermal">Térmico</el-dropdown-item>
                          <el-dropdown-item command="sticker">Sticker</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-button-group>
                    <quick-start-tip
                      title="Envía un WhatsApp a tu cliente"
                      content="Puede enviar un mensaje directamente al WhatsApp del cliente,
                      si el cliente registró su telefono."
                      placement="bottom"
                      :bg-purple="false"
                      :value="activeQuickStartTip === 10"
                      @onNextStep="handleNextStep">
                      <div class="inline-block" :class="{ 'quick-start-selected': $store.state.quickStart.step === 10 }">
                        <el-button @click="whatsapp" class="el-button--success mr-2" :class="{ 'p-2' : isMobile }" v-if="id">
                          <span v-if="!isMobile">WhatsApp</span>
                          <i v-if="isMobile" class='text-md bx bxl-whatsapp'></i>
                        </el-button>
                      </div>
                    </quick-start-tip>
                    <el-button @click="save" class="el-button--primary" :class="{ 'p-2' : isMobile }">
                      <span v-if="!isMobile">Guardar</span>
                      <i v-if="isMobile" class='text-md bx bx-save'></i>
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <div id="print-area" class="w-full mb-4 space-y-4 relative">
              <div class="flex flex-row items-center text-center py-2" style="justify-content: space-between;">
                <div class="w-1/3 bill-header">
                  <div class="w-full flex flex-row items-center">
                    <img src="@/assets/img/mini-responsive-samii.png" v-if="showLogo && !logo" alt="" width="70px">
                    <img :src="logo" v-if="showLogo && logo" alt="" :style="samiiInvoicesLogoSize">
                    <span class="font-bold text-gray-600 text-3xl font-headline ml-1"  v-if="showBusinessName">
                      {{ $store.state.business.name || 'Fixme up' }}
                    </span>
                  </div>
                </div>
                <div class="w-1/3 bill-header">
                  <div class="w-full">
                    <div class="border bill-container"># Orden</div>
                    <div class="border">
                      <div>
                        <strong>{{ order.reference }}</strong>
                      </div>
                      <div>
                        <strong>{{ todayDate }}</strong>
                      </div>
                      <div>
                        <strong>{{ dueDate }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bill-header">
                  <quick-start-tip
                    title="Seguimiento por QR"
                    content="Tu cliente puede dar seguimiento a su orden mediante el QR que se genera en su ticket"
                    placement="bottom"
                    :bg-purple="false"
                    :value="activeQuickStartTip === 11"
                    @onNextStep="handleNextStep">
                    <div :class="{ 'quick-start-selected': $store.state.quickStart.step === 11 }">
                      <qr-code
                        :value="qrCodeValue"
                        size="100"
                        level="H" />
                    </div>
                  </quick-start-tip>
                </div>
              </div>
              <div class="-mx-5 border-t border-gray-200" />
              <div class="w-full flex flex-row py-2">
                <div class="w-1/2 mr-1">
                  <div class="border bill-container">Negocio</div>
                  <div class="border">
                    <div><strong>Nombre:</strong> {{ $store.state.business.name }}</div>
                    <div><strong>Teléfono:</strong> {{ businessPhone }}</div>
                    <div><strong>Dirección:</strong> {{ $store.state.business.address }}</div>
                  </div>
                </div>
                <div class="w-1/2 ml-1">
                  <div class="border bill-container">Cliente</div>
                  <div class="border">
                    <div><strong>Nombre:</strong> {{ order.client.fullName }}</div>
                    <div><strong>Teléfono:</strong> {{ order.client.phone }}</div>
                    <div><strong>Correo:</strong> {{ order.client.email }}</div>
                    <div v-if="order.client.dni">
                      <strong>Numero de Identificación:</strong> {{ order.client.dni }}
                    </div>
                    <div v-if="order.client.contactDescription">
                      <strong>Dirección:</strong> {{ order.client.contactDescription }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-5 border-t border-gray-200" />
              <div v-if="order.orderDevices?.length" class="w-full items-center text-center py-2">
                <div class="border bill-container">Datos del Equipo</div>
                <div class="border block w-full overflow-x-auto">
                  <div class="w-full flex flex-row border-b">
                    <div class="w-1/12 font-bold text-center px-2">
                      #
                    </div>
                    <div class="w-3/12 font-bold text-left px-2">
                      Modelo
                    </div>
                    <div class="w-3/12 font-bold text-left px-2">
                      Nota (Descripción)
                    </div>
                    <div class="w-1/12 font-bold text-left px-2">
                      Cantidad
                    </div>
                    <div class="w-2/12 font-bold text-right px-2">
                      Precio
                    </div>
                    <div class="w-2/12 font-bold text-right px-2">
                      Total
                    </div>
                  </div>
                  <div
                    class="w-full flex flex-row border-b"
                    v-for="(device, index) in order.orderDevices"
                    :key="device.id">
                    <div class="w-1/12 text-center">
                      {{ index + 1 }}-
                    </div>
                    <div class="w-11/12 text-left">
                      <div class="w-12/12 flex flex-row">
                        <div class="w-3/12">
                          {{ `${device.deviceModel.brand} - ${device.deviceModel.model}` }}
                        </div>
                        <div class="w-3/12">
                          {{ device.diagnosticNotes }}
                        </div>
                      </div>
                      <div class="w-12/12" v-if="!device.serialNumberType || device.serialNumberType === 2">
                        IMEI: {{ device.imei }}
                      </div>
                      <div class="w-12/12" v-if="device.serialNumberType === 1">
                        SN: {{ device.imei }}
                      </div>
                      <div class="w-12/12" v-if="showPasscode && device.deviceLockType === 1">
                        PASSCODE: {{ device.passcode }}
                      </div>
                      <div class="w-12/12" v-if="showPasscode && device.deviceLockType === 2">
                        PATRON: {{ device.pattern }}
                      </div>
                      <div class="border-t py-2">
                        <div
                          class="w-full flex flex-col border-b"
                          v-for="breakdown in breakdownResume[device.id]"
                          :key="breakdown.id">
                          <div class="w-full flex flex-row">
                            <div class="w-3/12 text-left px-2" style="word-break: break-word;">
                              - {{ breakdown.name }}
                            </div>
                            <div class="w-4/12 text-left px-2" style="word-break: break-word;">
                              {{ breakdown.description }}
                            </div>
                            <div class="w-1/12 text-left px-2" style="word-break: break-word;">
                              {{ breakdown.quantity }}
                            </div>
                            <div class="w-2/12 text-right px-2" style="word-break: break-word;">
                              {{ moneyFormat(breakdown.price) }}
                            </div>
                            <div class="w-2/12 text-right px-2" style="word-break: break-word;">
                              {{ moneyFormat(breakdown.total) }}
                            </div>
                          </div>
                        </div>
                        <div class="w-full" v-if="device.hasWarranty">
                          Garantía: {{ `${device.warrantyDays} ${device.warrantyDays === 1 ? 'Dia' : 'Dias'}` }}
                        </div>
                        <div class="w-full flex flex-col border-t p-2 text-xs">
                          <div class="flex justify-between">
                            <div v-for="item in checklist(device.checklistItems).even" :key="item.id" class="cursor-not-allowed">
                              {{ item.name }}: <i :class="`text-sm bx ${item.value ? 'bx-check' : 'bx-x' }`" />
                            </div>
                          </div>
                          <div class="flex justify-between">
                            <div   v-for="item in checklist(device.checklistItems).odd" :key="item.id" class="cursor-not-allowed">
                              {{ item.name }}: <i :class="`text-sm bx ${item.value ? 'bx-check' : 'bx-x' }`" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="order.orderProducts?.length" class="w-full items-center text-center py-2">
                <div class="border bill-container">Ventas</div>
                <div class="border block w-full overflow-x-auto">
                  <div class="w-full flex flex-row border-b">
                    <div class="w-1/12 font-bold text-center px-2">
                      #
                    </div>
                    <div class="w-4/12 font-bold text-left px-2">
                      Producto
                    </div>
                    <div class="w-2/12 font-bold text-left px-2">
                      Cantidad
                    </div>
                    <div class="w-2/12 font-bold text-left px-2">
                      Precio unit.
                    </div>
                    <div class="w-3/12 font-bold text-right px-2">
                      Precio
                    </div>
                  </div>
                  <div
                    class="w-full flex flex-row border-b"
                    v-for="(product, index) in order.orderProducts"
                    :key="product.id">
                    <div class="w-full flex flex-row">
                      <div class="w-1/12 text-center px-2">
                        {{ index + 1 }} -
                      </div>
                      <div class="w-4/12 text-left px-2">
                        {{ product.name }}
                      </div>
                      <div class="w-2/12 text-left px-2">
                        {{ product.quantity || 1 }}
                      </div>
                      <div class="w-2/12 text-right px-2">
                        {{ moneyFormat(product.price) }}
                      </div>
                      <div class="w-3/12 text-right px-2">
                        {{ moneyFormat(product.price * (product.quantity || 1)) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-5 border-t border-gray-200" />
              <div class="w-full flex flex-row py-2">
                <div class="w-1/2 mr-1 uppercase" style="visibility: hidden;">
                  TOTAL EN LETRA: {{ order.totalAmount | moneyToWords }}
                </div>
                <div class="w-1/2 ml-1 bill-summary">
                  <div class="w-full flex flex-row">
                    <div class="border bill-container">Anticipo</div>
                    <div class="border grid text-right">
                      {{ moneyFormat(order.orderAdvances.reduce((acc, curr) => acc -= curr.advanceAmount, 0)) }}
                    </div>
                  </div>
                  <div class="w-full flex flex-row">
                    <div class="border bill-container">Subtotal</div>
                    <div class="border grid text-right">{{ moneyFormat(order.subtotalAmount) }}</div>
                  </div>
                  <div class="w-full flex flex-row">
                    <div class="border bill-container">Impuesto {{ order.taxRate }}%</div>
                    <div class="border grid text-right">{{ moneyFormat(order.taxAmount) }}</div>
                  </div>
                  <div class="w-full flex flex-row">
                    <div class="border bill-container">TOTAL</div>
                    <div class="border grid text-right">{{ moneyFormat(order.totalAmount) }}</div>
                  </div>
                </div>
              </div>
              <div class="-mx-5 border-t border-gray-200" />
              <div class="w-full pt-2">
                <strong>Metodo de Pago: </strong>{{ paymentMethod }}
              </div>
              <div class="w-full py-2">
                <div class="border bill-container">Garantía</div>
                <div class="border">
                  <policy />
                </div>
              </div>
              <div class="w-full py-2 text-center" style="text-align: -webkit-center;">
                <div
                  v-if="!signature"
                  @click="signInvoice"
                  class="w-48 h-28 border border-black flex items-center justify-center cursor-pointer">
                  <span class="text-gray-400 text-lg">Firma</span>
                </div>
                <img v-if="signature" :src="signature" alt="" class="border" style="height: 200px !important; width: max-content;" />
                <div>Nombre y Firma</div>
                <div>Cliente</div>
              </div>
              <div class="w-full absolute top-0 left-0">
                <img style="opacity: 0.3" :src="watermark" alt="" />
              </div>
            </div>
          </div>
        </div>
        <el-dialog :visible.sync="showSignature">
          <signature
            id="default-invoice"
            @signature:update="onSignatureUpdate"
          ></signature>
        </el-dialog>
      </div>
    </app-layout>
  </lock-scroll>
</template>
<style scoped>
.bill-header {
  justify-content: center;
  align-items: center;
}
.bill-container {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF;
  outline: none;
}
.bill-summary {
  display: inline-grid;
  justify-content: right;
}
.bill-summary > div * {
  min-width: 100px;
}
#print > span, div, strong {
  color: #000000;
}
@media print {
  body *:not(#print-area):not(#print-area *) {
    visibility: hidden;
  }
  #print-area {
    position: absolute;
    visibility: visible;
    top: 0;
    left: 16px;
    width: 8.5in;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  #print-area * {
    overflow-x: hidden;
  }
}
.terms-button {
  @apply bg-blue-500 text-white;
}

.terms-button:hover {
  @apply opacity-80;
}
</style>
<script>
import FileService from "@/services/FileService";
import BusinessGetters from '@/store/modules/business/getters';
import OrderService from '@/services/OrderService';
import numbers from '@/utils/numbers';
import { CategoryEnum, OrderStamp, PrintTypes } from '@/constants';
import StampWarranty from '@/assets/img/stamp-warranty.png';
import StampPay from '@/assets/img/stamp-pay.png';
import mappers from './mappers';
import { Whatsapp, getCountryCode } from '@/utils/phones';
import { tag, FileType } from '@/constants';
import responsiveSize from '@/mixins/responsiveSize';
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';
import { LOCAL_STORAGE_SAMII_KEYS, setItem } from '@/utils/storage';
import { dataURLtoFile } from '@/utils/files';

export default {
  name: 'InvoiceDefault',
  mixins: [responsiveSize],
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout'),
    Policy: () => import('@/components/molecules/Policy'),
    Signature: () => import('@/components/dialogs/Signature'),
  },
  data() {
    return {
      id: undefined,
      stamp: undefined,
      order: {
        client: {
          fullName: null,
          phone: null,
          email: null,
          contactDescription: null,
          dni: null,
        },
        orderDevices: [],
        orderAdvances: [],
        orderProducts: [],
        paymentMethod: {},
      },
      categoryEnum: {
        [CategoryEnum.Phone]: 'Teléfono',
        [CategoryEnum.Tablet]: 'Tableta',
        [CategoryEnum.Laptop]: 'Computadora Portátil',
        [CategoryEnum.Desktop]: 'Computadora Escritorio',
        [CategoryEnum.Wearable]: 'Usable',
      },
      OrderStamp: OrderStamp,
      breakdownResume: {},
      isMobile: false,
      activeQuickStartTip: 0,
      lockScroll: false,
      lockScreen: false,
      loading: false,
      showSignature: false,
      signature: null,
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.id = this.$route.params.id;
    this.lockScreen = this.$route.params.lockScreen || false;
    this.load();
  },
  computed: {
    dueDate() {
      if (this.order.dueDate) {
        const format = this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
        return this.$moment(this.order.dueDate).format(format);
      }
      return null;
    },
    todayDate() {
      const format = `${this.$store.state.business.dateFormat?.toUpperCase()} hh:mm:ss A` ?? "MM/DD/YYYY hh:mm:ss A";
      return this.$moment(new Date()).format(format);
    },
    showLogo() {
      const config = this.$store.state.invoices.config[PrintTypes.Letter];
      if (!config) {
        return true;
      }
      return config.showLogo;
    },
    showBusinessName() {
      const config = this.$store.state.invoices.config[PrintTypes.Letter];
      if (!config) {
        return true;
      }
      return config.showBusinessName;
    },
    showPasscode() {
      const config = this.$store.state.invoices.config[PrintTypes.Letter];
      if (!config) {
        return true;
      }
      return config.showPasscode;
    },
    logo() {
      return BusinessGetters.logo();
    },
    businessPhone() {
      const { phone, phoneCountryCodeId } = this.$store.state.business;
      const flag = this.$store.state.catalogs.flags.find((f) => f.id === phoneCountryCodeId);
      if (flag) {
        return `+${flag.countryCode} ${phone}`;
      }
      return phone;
    },
    client() {
      return {
        fullname: this.order.client ? this.order.client.fullName : '',
        email: this.order.client ? this.order.client.email : '',
        phone: this.order.client ? this.order.client.phone : '',
      }
    },
    watermark() {
      if (this.stamp === OrderStamp.Warranty) return StampWarranty;
      if (this.stamp === OrderStamp.Pay) return StampPay;
      return null;
    },
    qrCodeValue() {
      return `${window.location.origin}/my-order-status/${this.id}`;
    },
    samiiInvoicesLogoSize() {
      const config = this.$store.state.invoices.config[PrintTypes.Letter];
      if (!config) {
        return {
          width: `100px !important`,
          height: `100px !important`,
        }
      }
      return {
        width: `${config.logoX}px !important`,
        height: `${config.logoY}px !important`,
      }
    },
    paymentMethod() {
      return this.order.paymentMethod?.name;
    },
  },
  methods: {
    async signInvoice() {
      this.showSignature = true;
    },
    onSignatureUpdate(signature) {
      this.signature = signature;
      this.showSignature = false;
    },
    async whatsapp() {
      try {
        this.loading = true;
        const countryCode = getCountryCode(
          this.$store.state.catalogs.flags,
          this.order.client.phoneCountryCodeId,
        );
        if (!countryCode) {
          this.$toastr.e('El Cliente debe tener registrado el Código de Area');
          return;
        }
        const pdf = await OrderService.getPdfByOrderId({ orderId: this.id, businessInfoId: this.$store.state.business.id });
        Whatsapp({
          code: countryCode,
          phone: this.order.client.phone,
          text: `Estimado/a ${this.order.client.fullName}; Gracias por ponerse en contacto con nosotros. Su ticket de servicio se puede ver, imprimir o descargar como PDF mediante el enlace siguiente. ${tag.br}${tag.br}${pdf}`,
        });
        await OrderService.addHistoryWhatsapp({
          orderId: this.order.id,
          message: `Estimado/a ${this.order.client.fullName}; Gracias por ponerse en contacto con nosotros. Su ticket de servicio se puede ver, imprimir o descargar como PDF mediante el enlace siguiente. ${pdf}`,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    print(command) {
      if (typeof command === "string") {
        this.$router.push({ name: `invoices.${command}`, params: { id: this.id } });
      } else {
        window.print();
      }
    },
    async load() {
      const result = await OrderService.getById(this.id);
      this.order = mappers.mapGetResponse(result);
      this.signature  = this.order.signature;
      this.stamp = this.order.signals.paid
        ? OrderStamp.Pay : this.order.signals.warranty
          ? OrderStamp.Warranty : undefined;
      this.breakdownResume = mappers.mapGetBreakdownResume(this.order.orderDevices);
      this.activeQuickStartTip = this.$store.state.quickStart.step;
      if (this.text) {
        this.whatsapp();
      }
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    checklist(items) {
      const even = [];
      const odd = [];
      this.$store.state.checklistItems.entities.map((entity, index) => {
        const item = items.find((d) => d.checklistId === entity.id);

        if (index % 2 === 0) {
          even.push({
            id: entity.id,
            name: entity.name,
            value: item ? item.value : false,
          });
        } else {
          odd.push({
            id: entity.id,
            name: entity.name,
            value: item ? item.value : false,
          });
        }
      });
      return {
        even, odd,
      };
    },
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    handleNextStep() {
      const step = this.$store.state.quickStart.step;
      this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
      this.activeQuickStartTip++;
      if (this.activeQuickStartTip === 12) {
        this.$store.dispatch(QUICK_START_STORE, { step: 0 });
        setItem(LOCAL_STORAGE_SAMII_KEYS.QUICK_START_STATUS, 'done');
        this.$router.push({ name: 'home.index' });
      }
    },
    goPoliciesAndTerms() {
      const isOwner = this.$store.state.account.accountType === 1;
      const { canViewAdministration } = this.$store.state.account;
      if(isOwner || canViewAdministration){
        this.$router.push({ name: 'administration.orderPreferences' });
        return;
      }
      this.$swal('Aviso',
        'No tienes el permiso para realizar esta acción ',
        'error')
    },
    async save() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('File', dataURLtoFile(this.signature));
        formData.append('Type', FileType.OrderSignature);
        const signature = await FileService.create(formData);
        await OrderService.updateOrderSignature(this.id, signature);
        this.$confirm(`¿Quieres ir al listado de ordenes?`, {
          type: 'warning',
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
        }).then(() => {
          this.$router.push({ name: 'orders.index' });
        });
      } catch (e) {
        this.$router.push({ name: 'orders.index' });
      } finally {
        this.loading = false;
      }
    }
  },
  filters: {
    moneyToWords(money) {
      window.convertir=require('numero-a-letras');
      return window.convertir.NumerosALetras(money);
    },
  },
}
</script>
